import axios, { AxiosResponse } from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

axios.interceptors.request.use(
  function (config: any) {
    config.metadata = { startTime: Date.now().toString() }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response: any) {
    response.config.metadata.endTime = Date.now().toString()
    response.config.metadata.duration = response.config.metadata.endTime - response.config.metadata.startTime
    return response
  },
  function (error: any) {
    error.config.metadata.endTime = Date.now().toString()
    error.config.metadata.duration = error.config.metadata.endTime - error.config.metadata.startTime
    return Promise.reject(error)
  }
)

// TODO: depends on how complicated this gets, but should probably be moved to store
export const api = {
  BASE_URL: axios.defaults.baseURL,
  async templateList(formType = ''): Promise<AxiosResponse> {
    if (formType === '') {
      return axios.get('/template/list')
    }
    return axios.get(`/template/list?sampling_type=${formType}`)
  },
  async templateValidate(data: FormData, id: string, accessToken: string): Promise<AxiosResponse> {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`
    }

    return axios.post(`/template/validate?template_id=${id}`, data, {
      headers
    })
  },
  async templateValidateSSD(
    metadataTemplateID: string | null,
    occurrenceTemplateID: string | null,
    siteTemplateID: string | null,
    siteVisitTemplateID: string | null,
    data: FormData,
    accessToken: string
  ): Promise<AxiosResponse> {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`
    }
    const params = {
      metadata_template_id: metadataTemplateID,
      occurrence_data_template_id: occurrenceTemplateID,
      site_data_template_id: siteTemplateID,
      site_visit_data_template_id: siteVisitTemplateID
    }
    return axios.post('/template/validate/ssd-templates', data, {
      params,
      headers
    })
  },
  async templateDataset(data: FormData, id: string, accessToken: string): Promise<AxiosResponse> {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`
    }

    return axios.post(`/template/incidental/dataset?template_id=${id}`, data, {
      headers
    })
  },
  async submitSSD(
    metadataTemplateID: string | null,
    occurrenceTemplateID: string | null,
    siteTemplateID: string | null,
    siteVisitTemplateID: string | null,
    data: FormData,
    accessToken: string
  ): Promise<AxiosResponse> {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`
    }
    const params = {
      metadata_template_id: metadataTemplateID,
      occurrence_data_template_id: occurrenceTemplateID,
      site_data_template_id: siteTemplateID,
      site_visit_data_template_id: siteVisitTemplateID
    }
    return axios.post('/template/systematic-survey/dataset', data, {
      params,
      headers
    })
  },
  async submissionStatus(id: string): Promise<AxiosResponse> {
    return axios.get('/submission/status', {
      params: {
        submission_id: id
      }
    })
  },
  auth: {
    async refreshToken(refresh: string): Promise<
      AxiosResponse<{
        access: string
        refresh: string
      }>
    > {
      return axios.post('/auth/refresh-token/', {
        refresh
      })
    },
    async emailSecureLink(email: string): Promise<
      AxiosResponse<{
        detail: [
          {
            loc: Array<string>
            msg: string
            type: string
          }
        ]
      }>
    > {
      return axios.post('/auth/email_secure_link/', {
        email
      })
    }
  }
}
