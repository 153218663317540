import { api } from '@/api'
import { Template, TemplateLoaderResult, templateTypeValues, TemplateTypeValues } from '@/models/api/template'
import { AxiosError } from 'axios'
import { useProgrammatic } from '@oruga-ui/oruga-next'
import AxiosErrorNotification from '@/components/AxiosErrorNotification.vue'

const { oruga } = useProgrammatic()

export const printAxiosError = (aerr: AxiosError): void => {
  oruga.notification.open({
    component: AxiosErrorNotification,
    props: {
      axiosError: aerr
    },
    variant: 'danger',
    position: 'bottom',
    indefinite: true, // don't close automatically, the component will handle it
    closable: true
  })
}

// Convert snake_case to camelCase
const snakeToCamel = (str: string) => {
  return str.toLowerCase().replace(
    /([-_][a-z])/g,
    (
      group // for each found groups
    ) =>
      group
        .toUpperCase() // Set first letter to capital
        .replace('-', '') // Remove dash (Yeah this handle kebab-case too :D )
        .replace('_', '') // Remove underscore
  )
}

// shorthand for Object.keys(obj).reduce <=> apply a function to each key of an object
const mapKeys = (obj: any, fn: (key: string) => string): typeof obj => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[fn(key)] = obj[key]
    return acc
  }, {} as any)
}

class TemplateLoaderClass {
  private templateList: { [type in TemplateTypeValues]?: Template[] } = {}
  private latestTemplates: { [type in TemplateTypeValues]?: Template } = {}

  public async loadTemplateList(): Promise<TemplateLoaderResult> {
    if (Object.keys(this.templateList).length === templateTypeValues.length) {
      const ResultSnakeCase = {
        ...this.templateList,
        ...mapKeys(this.latestTemplates, (key) => key + '_latest')
      }

      const ResultCamelCase = mapKeys(ResultSnakeCase, snakeToCamel)

      return ResultCamelCase as TemplateLoaderResult
    }

    await api
      .templateList()
      .then(({ data }) => {
        const templateList = data as Array<Template>
        for (const template of templateList) {
          if (this.templateList[template.name] === undefined) {
            this.templateList[template.name] = []
          }
          template.download_url = `${api.BASE_URL}/template/download?template_id=${template.id}`
          this.templateList[template.name]?.push(template)
        }

        for (const type of templateTypeValues) {
          this.latestTemplates[type] = this.findLatest(this.templateList[type] || [])
        }
      })
      .catch(printAxiosError)

    // Templates, but with snake_case keys
    const ResultSnakeCase = {
      ...this.templateList, // Templates list
      ...mapKeys(this.latestTemplates, (key) => key + '_latest') // Latest templates
    }

    // Convert snake_case to camelCase and return it
    const ResultCamelCase = mapKeys(ResultSnakeCase, snakeToCamel)
    return ResultCamelCase as TemplateLoaderResult
  }

  // Find the latest template in a list
  public findLatest(template: Template[]): Template {
    return template.sort((t1, t2) => t2.version.localeCompare(t1.version))[0]
  }

  // Should not really be used :/ prefer loadTemplateList.then(...) instead
  public getLatestTemplate(type: TemplateTypeValues): Template | undefined {
    if (!this.latestTemplates[type]) {
      this.latestTemplates[type] = this.findLatest(this.templateList[type] || [])
    }
    return this.latestTemplates[type]
  }

  // Should not really be used :/ prefer loadTemplateList.then(...) instead
  public getTemplateList(type: TemplateTypeValues): Template[] | undefined {
    if (!this.templateList[type]) {
      throw new Error('No templates found, load the template list first')
    }
    return this.templateList[type]
  }
}

// Singleton ? bro, just export the instance instead of the class :)
export const TemplateLoader = new TemplateLoaderClass()
